import React from 'react';
import { AuthProvider } from './src/AuthContext';

import "@fontsource/montserrat/variable.css"
import "@fontsource/merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
import './src/styles/global.css'
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

export const wrapRootElement = ({ element }) => {
  return <AuthProvider>{element}</AuthProvider>;
};// custom typefaces
